import React, { Component } from 'react'
import axios from '../components/axios'
// import { injectIntl } from 'gatsby-plugin-intl'

import tempIcon from '../assets/images/temp-icon.svg'
import humidityIcon from '../assets/images/humidity-icon.svg'
import vocIcon from '../assets/images/voc-icon.svg'
import co2Icon from '../assets/images/co2-icon.svg'

class AirValues extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      fetchedData: [],
    }
  }

  getAirValues() {
    axios
      .get('/get/airvalues', {})
      .then(res => {
        const data = res.data
        this.setState({
          fetchedData: data,
          loading: false,
        })
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidMount() {
    this.getAirValues()
    this.interval = setInterval(() => this.getAirValues(), 10000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    // const { data } = this.props
    const { loading, fetchedData } = this.state
    return (
      <>
        {loading ? (
          <p>LOADING...</p>
        ) : (
          <div className='airValues'>
            <div>
              <img src={tempIcon} alt='Temperature Icon' />
              <div className='item'>
                <p>
                  {fetchedData.Temperature}&deg;<sup>C</sup>
                </p>
              </div>
            </div>
            <div className='item'>
              <img src={humidityIcon} alt='Humidity Icon' />
              <p>
                {fetchedData.Humidity}
                <sup>&#37;</sup>
              </p>
            </div>
            <div className='item'>
              <img src={vocIcon} alt='VOC Icon' />
              <p>
                {fetchedData.VOC}
                <sup>&#37;</sup>
              </p>
            </div>
            <div className='item'>
              <img src={co2Icon} alt='CO2 Icon' />
              <p>
                {fetchedData.CO2}
                <sup>ppm</sup>
              </p>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default AirValues
