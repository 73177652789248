import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import SEO from '../components/seo'
import AirValues from '../components/airValues'

const AirValuesPage = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'navigation.airValues' })} />
    <div
      style={{
        maxWidth: `800px`,
        height: `100%`,
        margin: `0 auto`,
        display: `flex`,
        alignItems: `center`,
      }}
    >
      <AirValues />
    </div>
  </>
)

export default injectIntl(AirValuesPage)
